import { Outlet } from "@remix-run/react";

export default function AuthLayout() {
    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img
                        className="mx-auto h-10 w-auto"
                        src="/images/logo.svg"
                        alt="Dragotec Logo"
                    />
                </div>
                <Outlet />
            </div>
        </>
    );
}
